import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AccountWalletButton from 'components/AccountWalletButton'
import { useTranslation } from 'contexts/Localization'
import { languageList } from 'config/localization/languages'
import LangSelector from '../LangSelector'
import NetworkSelector from './NetworkSelector'

const StyledI = styled.i`
  color: white;
`

const StyledMobileImg = styled.img`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`

const Menu = () => {
  const { account } = useWeb3React()

  const [showMenu, setshowMenu] = useState(false)

  const toggleMenu = () => {
    setshowMenu(!showMenu)
  }

  const { t, setLanguage, currentLanguage } = useTranslation()

  return (
    <>
      <div id='navbar' className='navbar-area'>
        <div className='raimo-responsive-nav'>
          <div className='container'>
            <div className='raimo-responsive-menu'>
              {/* <div role='presentation' onClick={() => toggleMenu()} className='hamburger-menu'>
                {showMenu ? (
                  <StyledI className='bx bx-x' />
                ) : (
                  <StyledI className='bx bx-menu' />
                )}
              </div> */}
              <div className='logo'>
                <a href='#!'>
                  <StyledMobileImg src='/images/NIPPONPUNK-logo.png' alt='logo' style={{ maxWidth: '200px' }} />
                </a>
              </div>
              {/* <div className='responsive-others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  <div className='option-item'>
                    <NetworkSelector />
                  </div>
                  <div className='option-item'>
                    {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <nav className={showMenu ? 'show navbar navbar-expand-md navbar-light' : 'navbar navbar-expand-md navbar-light hide-menu'}>
          <div className='container'>
            <a href='#!' className='navbar-brand'>
              <img src='/images/NIPPONPUNK-logo.png' alt='logo' style={{ maxWidth: '200px' }} />
            </a>
            <div className='collapse navbar-collapse mean-menu'>
              <ul className='navbar-nav'>
                {/* <li className='nav-item'>
                  <Link to="/#!" className='nav-link'>{t("Pot")} </Link>
                </li> */}
              </ul>
              {/* <div className='others-option'>
                <div className='d-flex align-items-center'>
                  <div className='option-item'>
                    <LangSelector currentLang={currentLanguage.code} langs={languageList} setLang={setLanguage} />
                  </div>
                  <div className='option-item'>
                    <NetworkSelector />
                  </div>
                  <div className='option-item'>
                    {!account ? <ConnectWalletButton /> : <AccountWalletButton account={account} />}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Menu
