import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useUpdateNetwork } from 'state/network/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import styled from 'styled-components'
import Menu from './components/Menu'
import Footer from './components/Footer'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'

const StyledMasterCont = styled.div`
  background: url("/images/landing-bg-V5.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 90%;
  background-position-y: top;
  // min-height: 800px;

  height: 100%;
`

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const FlexMaster = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
`

const FlexMajor = styled.div`
  flex-grow: 3;
  height: 100%;
`

const FlexMinor = styled.div`
  flex-grow: 1;
`

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useUpdateNetwork()

  return (
    <Router history={history}>
      <FlexMaster>
        <FlexMajor>
          <StyledMasterCont className="master-container">
            <Menu />
            <SuspenseWithChunkError fallback={<PageLoader />}>
              {/* <AnimatePresence initial={false} exitBeforeEnter> */}
                <Switch>
                  {/* <Route exact path="/mint" component={Mint} /> */}
                  <Route component={ComingSoon} />

                  {/* 404 */}
                  {/* <Route component={NotFound} /> */}
                </Switch>
              {/* </AnimatePresence> */}
            </SuspenseWithChunkError>
            <ToastListener />
            <DatePickerPortal />
          </StyledMasterCont>
        </FlexMajor>
        <FlexMinor>
          <Footer />
        </FlexMinor>
      </FlexMaster>
    </Router>
  )
}

export default React.memo(App)
