import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useRefresh from 'hooks/useRefresh'
import { isNil } from 'lodash'
import axios from 'axios'
import { ReactComponent as OSSvg } from 'assets/os-white.svg'

const FooterPanel = styled.div`
  width: 100%;
  display: flex;
  background-color: #F2ECE4;
  color: white;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
  column-gap: 100px;
  justify-content: space-between;
  height: 100%;
`

const CopyrightLabel = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 5px;
  color: #333333;
`

const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 20;
  justify-content: center;
`

const SocialPanel = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: center;
  column-gap: 30px;
`

const MenuPanel = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  column-gap: 100px;
  font-size: 16px;
`

const DescriptionPanel = styled.div`
  display: flex;
  color: white;
  padding-top: 45px;
  font-weight: 700;
`

const Background = styled.div`
  background-color: #F2ECE4;
`
const StyledA = styled.a`
  font-size: 24px;

`

const Footer = () => {
  // const currentYear = new Date().getFullYear()
  const { slowRefresh } = useRefresh()
  const baseUrl = `${process.env.REACT_APP_BO_LINK}/api/frontend/get-social-link`
  const [allLinks, setAllLinks] = useState<any>({})

  // get all links
  useLayoutEffect(() => {
    const getAllLinks = async (): Promise<void> => {
      await axios.get(baseUrl).then((response) => {
        if (response && response.status === 200) {
          const respData = response.data.data
          setAllLinks(respData)
        }
      }).catch(error => {
        // console.log('error', error)
      })
    }

    getAllLinks()
  }, [baseUrl, slowRefresh])


  return (
    <Background className="footer-layout">
      <div className="container" style={{ height: '100%' }}>
        <FooterPanel className="footer-panel">
          <LeftArea className="left-area">
            {/* <img width={85} src="/images/NIPPONPUNK-logo.png" alt="logo" /> */}
            <CopyrightLabel>&copy; {process.env.REACT_APP_PROJECT_NAME}</CopyrightLabel>
          </LeftArea>

          <RightArea>
            {/* <MenuPanel className="menu-panel">
              <Link to="/#!" className="footer-nav-text">{t("POOL")}</Link>
            </MenuPanel> */}

            <SocialPanel>
              {!isNil(allLinks.twitter) && allLinks.twitter !== '' ?
                <StyledA  href={allLinks.twitter} target="_BLANK" rel="noopener noreferrer">
                  <i className="bx bxl-twitter" />
                </StyledA>
                : null}
              {!isNil(allLinks.medium) && allLinks.medium !== '' ?
                <StyledA  href={allLinks.medium} target="_BLANK" rel="noopener noreferrer">
                  <i className="bx bxl-medium" />
                </StyledA>
                : null}
              {!isNil(allLinks.opensea) && allLinks.opensea !== '' ?
                <a href={allLinks.opensea} target="_BLANK" rel="noopener noreferrer">
                  <OSSvg width={24} height={28} />
                </a>
                : null}
              {!isNil(allLinks.telegram) && allLinks.telegram !== '' ?
                <StyledA  href={allLinks.telegram} target="_BLANK" rel="noopener noreferrer">
                  <i className="bx bxl-telegram" />
                </StyledA>
                : null}
            </SocialPanel>
          </RightArea>
        </FooterPanel>
      </div>
    </Background>
  );
}

export default Footer
